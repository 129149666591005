import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _38793a4b = () => interopDefault(import('../_templates/pages/blog/Blog.vue' /* webpackChunkName: "" */))
const _20a6f04b = () => interopDefault(import('../_templates/pages/checkout/Checkout.vue' /* webpackChunkName: "" */))
const _f1a09a2c = () => interopDefault(import('../_templates/pages/checkout/Checkout/Order.vue' /* webpackChunkName: "" */))
const _e919856e = () => interopDefault(import('../_templates/pages/checkout/Checkout/Account.vue' /* webpackChunkName: "" */))
const _7654d669 = () => interopDefault(import('../_templates/pages/checkout/Checkout/ThankYou.vue' /* webpackChunkName: "" */))
const _1c5a1736 = () => interopDefault(import('../_templates/pages/customer/MyAccount/MyAccount.vue' /* webpackChunkName: "" */))
const _8ce706b8 = () => interopDefault(import('../_templates/pages/customer/MyAccount/AddressesDetails/AddressesDetails.vue' /* webpackChunkName: "" */))
const _be460fc0 = () => interopDefault(import('../_templates/pages/customer/MyAccount/MyProfile/MyProfile.vue' /* webpackChunkName: "" */))
const _080fd6e4 = () => interopDefault(import('../_templates/pages/customer/MyAccount/OrderHistory/OrderHistory.vue' /* webpackChunkName: "" */))
const _dfafe720 = () => interopDefault(import('../_templates/pages/customer/MyAccount/AddressesDetails/AddressNew.vue' /* webpackChunkName: "" */))
const _f642db2c = () => interopDefault(import('../_templates/pages/customer/MyAccount/AddressesDetails/AddressEdit.vue' /* webpackChunkName: "" */))
const _2d9b258f = () => interopDefault(import('../_templates/pages/customer/MyAccount/OrderHistory/SingleOrder/SingleOrder.vue' /* webpackChunkName: "" */))
const _442792b9 = () => interopDefault(import('../_templates/pages/faq/Faq.vue' /* webpackChunkName: "" */))
const _05209f2b = () => interopDefault(import('../_templates/pages/wishlist/Wishlist.vue' /* webpackChunkName: "" */))
const _7ff86e4b = () => interopDefault(import('../_templates/pages/search/Search.vue' /* webpackChunkName: "" */))
const _eb795342 = () => interopDefault(import('../_templates/pages/customer/MyAccount/ResetPassword.vue' /* webpackChunkName: "" */))
const _268898f9 = () => interopDefault(import('../_templates/pages/theme/Home.vue' /* webpackChunkName: "" */))
const _3eec54ea = () => interopDefault(import('../_templates/pages/blog/BlogPost.vue' /* webpackChunkName: "" */))
const _2b70728e = () => interopDefault(import('../_templates/pages/attribute-collection/AttributeCollection.vue' /* webpackChunkName: "" */))
const _9c34b57e = () => interopDefault(import('../_templates/pages/attribute-collection/AttributeCollectionItem.vue' /* webpackChunkName: "" */))
const _76bdaea9 = () => interopDefault(import('../_templates/pages/theme/Page.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/aktuelt.html",
    component: _38793a4b,
    name: "blog___default"
  }, {
    path: "/checkout",
    component: _20a6f04b,
    name: "checkout___default",
    children: [{
      path: "bestilling",
      component: _f1a09a2c,
      name: "checkout-order___default"
    }, {
      path: "konto",
      component: _e919856e,
      name: "checkout-account___default"
    }, {
      path: "takk",
      component: _7654d669,
      name: "checkout-thank-you___default"
    }]
  }, {
    path: "/customer",
    component: _1c5a1736,
    meta: {"titleLabel":"Account"},
    name: "customer___default",
    children: [{
      path: "addresses-details",
      component: _8ce706b8,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___default"
    }, {
      path: "my-profile",
      component: _be460fc0,
      meta: {"titleLabel":"My Account"},
      name: "customer-my-profile___default"
    }, {
      path: "order-history",
      component: _080fd6e4,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___default"
    }, {
      path: "addresses-details/create",
      component: _dfafe720,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___default"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _f642db2c,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___default"
    }, {
      path: "order-history/:orderId",
      component: _2d9b258f,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___default"
    }]
  }, {
    path: "/faq.html",
    component: _442792b9,
    name: "faq___default"
  }, {
    path: "/onskeliste",
    component: _05209f2b,
    name: "wishlist___default"
  }, {
    path: "/search",
    component: _7ff86e4b,
    name: "search___default"
  }, {
    path: "/customer/reset-password",
    component: _eb795342,
    alias: "/customer/account/createPassword",
    name: "reset-password___default"
  }, {
    path: "/",
    component: _268898f9,
    name: "home___default"
  }, {
    path: "/aktuelt/:slug+.html",
    component: _3eec54ea,
    name: "blogPost___default"
  }, {
    path: "/collection/:slug([^/.]+).html",
    component: _2b70728e,
    name: "attributeCollection___default"
  }, {
    path: "/collection/:collection_slug/:slug+.html",
    component: _9c34b57e,
    name: "attributeCollectionItem___default"
  }, {
    path: "/:slug+",
    component: _76bdaea9,
    name: "page___default"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
