<template>
  <nav class="cart-navigation">
    <ul>
      <slot name="front"></slot>
      <li v-if="searchIcon" class="cart-navigation-item">
        <SfButton
          class="sf-button--pure"
          @click="$emit('click:search')"
        >
          <wm-icon
            name="magnifying-glass"
            :title="$t('Search')"
            width="1.6875rem"
            height="1.6875rem"
          />
        </SfButton>
      </li>
      <li v-if="accountIcon" class="desktop-only cart-navigation-item">
        <AccountIcon class="header-button" />
      </li>
      <li v-if="wishlistIcon" class="cart-navigation-item">
        <WishlistIcon />
      </li>
      <li class="quote-cart-navigation-item">
        <QuoteCartIcon />
      </li>
      <li v-if="cartIcon" class="cart-navigation-item">
        <CartIcon />
      </li>
      <slot name="back"></slot>
    </ul>
  </nav>
</template>
<script>
import { defineComponent } from '@nuxtjs/composition-api';
import { SfButton } from '@storefront-ui/vue';

export default defineComponent({
  name: 'CartNavigation',
  components: {
    SfButton,
    WishlistIcon: () => import('components/wishlist/WishlistIcon'),
    AccountIcon: () => import('components/theme/Icons/AccountIcon.vue'),
    CartIcon: () => import('components/theme/Icons/CartIcon.vue'),
    QuoteCartIcon: () => import('components/quote-cart/QuoteCartIcon.vue')
  },
  props: {
    searchIcon: {
      type: Boolean,
      default: false
    },
    accountIcon: {
      type: Boolean,
      default: false
    },
    wishlistIcon: {
      type: Boolean,
      default: true
    },
    cartIcon: {
      type: Boolean,
      default: true
    }
  }
})
</script>
<style lang="scss">
@import 'styles/theme/components/Header/CartNavigation';
</style>