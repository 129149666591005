var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sf-header",class:{ 'is-sticky': _vm.sticky, 'is-hidden': _vm.hidden }},[_c('div',{staticClass:"sf-header__wrapper"},[_c('header',{ref:"header",staticClass:"sf-header__header"},[_vm._t("logo",function(){return [_c('SfLink',{attrs:{"link":"/"}},[(_vm.logo)?_c('SfImage',{staticClass:"sf-header__logo",attrs:{"src":_vm.logo,"alt":_vm.title,"width":_vm.logoWidth,"height":_vm.logoHeight}}):_c('h1',{staticClass:"sf-header__title"},[_vm._v(_vm._s(_vm.title))])],1)]},null,{ logo: _vm.logo, title: _vm.title }),_vm._v(" "),_c('div',{staticClass:"sf-header__aside"},[_vm._t("aside")],2),_vm._v(" "),_c('div',{staticClass:"sf-header__actions"},[_c('nav',{staticClass:"sf-header__navigation",class:{ 'is-visible': _vm.isNavVisible }},[_vm._t("navigation")],2),_vm._v(" "),_vm._t("search",function(){return [_c('SfSearchBar',{staticClass:"sf-header__search",attrs:{"value":_vm.searchValue,"placeholder":_vm.searchPlaceholder,"aria-label":"Search"},on:{"input":function($event){return _vm.$emit('change:search', $event)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.$emit('enter:search', $event)}}})]},null,{ searchValue: _vm.searchValue, searchPlaceholder: _vm.searchPlaceholder }),_vm._v(" "),_vm._t("header-icons",function(){return [_c('div',{staticClass:"sf-header__icons"},[_c('SfButton',{staticClass:"sf-button--pure sf-header__action",class:{ 'display-none': !_vm.accountIcon },attrs:{"data-testid":"accountIcon","aria-label":"Account"},on:{"click":function($event){return _vm.$emit('click:account')}}},[_c('SfIcon',{class:{
                  'sf-header__icon is-active': _vm.activeIcon === 'account',
                },attrs:{"icon":_vm.accountIcon,"size":"1.25rem"}})],1),_vm._v(" "),_c('SfButton',{staticClass:"sf-button--pure sf-header__action",class:{ 'display-none': !_vm.wishlistIcon },attrs:{"data-testid":"wishlistIcon","aria-label":"Wishlist"},on:{"click":function($event){return _vm.$emit('click:wishlist')}}},[_c('SfIcon',{staticClass:"sf-header__icon",class:{
                  'sf-header__icon is-active': _vm.activeIcon === 'wishlist',
                },attrs:{"icon":_vm.wishlistIcon,"has-badge":_vm.wishlistHasProducts,"badge-label":_vm.wishlistItemsQty,"size":"1.25rem"}})],1),_vm._v(" "),_c('SfButton',{staticClass:"sf-button--pure sf-header__action",class:{ 'display-none': !_vm.cartIcon },attrs:{"data-testid":"cartIcon","aria-label":"Cart"},on:{"click":function($event){return _vm.$emit('click:cart')}}},[_c('SfIcon',{staticClass:"sf-header__icon",class:{
                  'sf-header__icon is-active': _vm.activeIcon === 'cart',
                },attrs:{"icon":_vm.cartIcon,"has-badge":_vm.cartHasProducts,"badge-label":_vm.cartItemsQty,"size":"1.25rem"}})],1)],1)]},null,{
            activeIcon: _vm.activeIcon,
            cartHasProducts: _vm.cartHasProducts,
            cartItemsQty: _vm.cartItemsQty,
            cartIcon: _vm.cartIcon,
            wishlistIcon: _vm.wishlistIcon,
            accountIcon: _vm.accountIcon,
          })],2)],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }