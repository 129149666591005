const moduleOptions = `{"blocks":{"footer":"footer","topMenu":"top-menu"},"logo":{"width":272,"height":48}}`;
var plugin_default = (context, inject) => {
  var _a;
  const options = JSON.parse(moduleOptions);
  let wm = (_a = context.$wm) != null ? _a : {};
  wm.theme = options;
  inject("wm", wm);
};
export {
  plugin_default as default
};
